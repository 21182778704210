import * as React from 'react'
import LayoutWork from '../../components/layoutWork'
import {Container, Row, Col} from "react-bootstrap" 
import head from '../../images/portfolio/antidepressBots/antidrepressBots.png'
import closeup1 from '../../images/portfolio/antidepressBots/antidrepressBots-closeup1.png'
import closeup2 from '../../images/portfolio/antidepressBots/antidrepressBots-closeup2.png'
import { Link } from 'gatsby'

const PortfolioAntiPage = () => {
  return (
    <LayoutWork pageTitle="Antidepress Bots">
      <Container><p className='info'>Installation | With | <Link to='https://lodedijkers.webflow.io/'>Lode Dijkers</Link> &#38; Robbert Koning</p></Container><hr/>
      <Container>
        <br/>
        <h1>Antidepress Bots</h1>
        <h2>A Social Media Activistic System to Combat the Depressive Filter Bubble.</h2>
        <br/>
        <img src={head} className="fullimg" alt="Intallation of antidepress bots"/>
        <br/>
        <br/>
        <p>The Social Media Activistic System to Combat the Depressive Filter Bubble is an innovative project that seeks to alleviate the negative effects of recommendation algorithms on depressed social media users. These algorithms are designed to personalise our feeds by showing us content that we are likely to engage with based on our previous interactions. However, they can also create a "depressive filter bubble" where users are exposed to depressive content that reinforces their negative emotions.</p>
        <br/>
        <p>To counter this, the project uses social media bots that behave like fake users to interact with positive content while following many depressive accounts. By confusing the algorithm in this way, the bots are able to push positive content to users in the depressive filter bubble. The bots interact with images of clear blue skies, a universal symbol of happiness, to counter the depressive content. Additionally, blue light, which is known to have a calming effect, is also used as a prevention method in places with high suicide rates.</p>
        <Row>
          <Col sm={6}>
              <Row>
                <img src={closeup1} alt="close-up of bot-network"/>
              </Row>
              <br/>
          </Col>
          <Col sm={6}>
              <Row>
                <img src={closeup2} alt="close-up of depressive content"/>
              </Row>
          </Col>
        </Row>
        <br/>
        <p>The bots are displayed on a website that functions as a terminal, allowing their activity to be viewed live. The code of the project is open-source, allowing anyone to download and run the bots to help relieve depressed social media users worldwide. This collective activist stance against the negative influence of algorithms is an important step towards creating a healthier online environment for all users.</p>
        <br/>
        <p>Overall, the Social Media Activistic System to Combat the Depressive Filter Bubble is an exciting project that addresses an important issue in our increasingly digital lives. Its use of social media bots to disrupt the depressive filter bubble is a creative and effective solution, and its open-source nature makes it accessible to anyone who wants to make a positive impact on social media.</p>
        <iframe width="100%" height="550" src="https://www.youtube.com/embed/NVWmSUPs4Eg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </Container>
    </LayoutWork>
    
  )
}
export default PortfolioAntiPage